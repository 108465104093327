import * as React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { SvgCsrLogo } from "../components/svg/SvgCsrLogo";
import { NetlifyForm, WmkLink } from "wmk-lib";
import { StaticImage } from "gatsby-plugin-image";
import { FaTimesCircle } from "react-icons/fa";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import ReactPlayer from "react-player";

const colorCsrGreen = { hex: `#66C571` };
const colorCsrPurple = { hex: `#151238`, r: 21, g: 18, b: 56 };

const linkStyle = `  a {
    text-decoration: none;
    color: ${colorCsrGreen.hex};
    transition: all 0.3s ease;
    &:hover {
      text-decoration: underline;
      transition: all 0.3s ease;
      color: black;
    }
  }`;

const PageStyle = styled.div`
  &,
  p,
  a {
    font-family: "Roboto", sans-serif;
  }
  position: relative;
  @media screen and (max-width: 740px) {
    .phone-col {
      position: relative;
      order: 2;
    }
  }
`;

const StyledLogo = styled(Col)`
  svg {
    margin: 4vh 0;
  }
`;

const StyledLargePhoneNumber = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 40px;
  font-weight: 900;
  @media screen and (max-width: 740px) {
    font-size: 22px;
  }
  ${linkStyle}
`;

const SimpleSvgHeader = ({ phone = `720-730-6457` }) => {
  return (
    <header>
      <Container>
        <Row>
          <StyledLogo>
            <SvgCsrLogo />
          </StyledLogo>
          <StyledLargePhoneNumber>
            <WmkLink tel>{phone}</WmkLink>
          </StyledLargePhoneNumber>
        </Row>
      </Container>
    </header>
  );
};

const StyledPhoneImage = styled.div``;

const StyledFooterCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorCsrGreen.hex};
  ${linkStyle}
`;

const SimpleSvgFooter = ({
  phone = `720-730-6457`,
  address = `1555 W. Thomas Ave. Englewood, CO 80110`
}) => {
  return (
    <footer>
      <div
        style={{ height: `1.5vh`, width: `100%`, backgroundColor: `black` }}
      />
      <Container>
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              padding: `2vh 0 3vh 0`
            }}>
            <div style={{ transform: `scale(.65)` }}>
              <SvgCsrLogo />
            </div>
          </Col>
          <StyledFooterCol>
            <WmkLink tel>{phone}</WmkLink>
          </StyledFooterCol>
          <StyledFooterCol>{address}</StyledFooterCol>
        </Row>
      </Container>
    </footer>
  );
};

const StyledGetAppButton = styled.button`
  background: none;
  border: none;
  padding: 0.75rem 1.25rem;
  background-color: ${colorCsrGreen.hex};
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3vh;
  letter-spacing: 0.125vw;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
  &:hover {
    background-color: ${colorCsrPurple.hex};
    transition: all 0.5s ease;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.75);
  }
`;

const StyledPopUpForm = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  top: ${({ show }) => (show ? 0 : `-100%`)};
  z-index: ${({ show }) => (show ? 100 : -1)};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 0.5s ease;
  & > div {
    padding: 4vh 6vw;
    background: rgba(
      ${colorCsrPurple.r},
      ${colorCsrPurple.g},
      ${colorCsrPurple.b},
      0.9
    );
    box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.2);
  }
  input,
  select {
    margin-bottom: 0.5rem;
  }
  button[type="submit"] {
    background: ${colorCsrGreen.hex};
    font-weight: 900;
    transition: all 0.3s ease;
    border: 1px solid ${colorCsrGreen.hex};
    text-transform: uppercase;
    border-radius: 0;
    &:hover {
      background: ${colorCsrPurple.hex};
      transition: all 0.3s ease;
    }
  }
`;

const FormPopUp = ({ show, click }) => {
  return (
    <StyledPopUpForm show={show}>
      <div>
        <Row className="flex-column">
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingBottom: `1rem`
            }}>
            <button
              onClick={click}
              style={{
                background: `none`,
                border: "none",
                fontSize: `2rem`,
                color: `white`
              }}>
              <FaTimesCircle />
            </button>
          </Col>
          <Col>
            <NetlifyForm
              fields={[
                { as: "name" },
                { as: `email` },
                { as: `phone` },
                { as: "address", props: { isRequired: true } }
              ]}
              config={{
                name: `getHoverQuote`,
                submit: "Let's Get Started",
                postUrl: "/hover"
              }}
            />
          </Col>
        </Row>
      </div>
    </StyledPopUpForm>
  );
};

const HoverLandingPage = () => {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const data = useStaticQuery(query);
  const videoUrl = get(data, `video.file.url`);
  const placeholder = get(data, `placeholder`);

  const handleClick = () => {
    setShowPopUp(!showPopUp);
  };

  return (
    <PageStyle>
      <FormPopUp show={showPopUp} click={handleClick} />
      <SimpleSvgHeader />
      <Container fluid style={{ padding: 0, position: "relative", zIndex: 50 }}>
        <Row>
          <Col xs={12} sm={12} md={5} className="phone-col">
            <StyledPhoneImage>
              <StaticImage
                src="../images/phone_image.png"
                alt="get a quote"
                quality={90}
              />
            </StyledPhoneImage>
          </Col>
          <Col xs={12} sm={12} md={7}>
            <Row className="flex-column">
              <Col>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    width: `100%`,
                    zIndex: 10,
                    bottom: `-2rem`
                  }}>
                  <StyledGetAppButton onClick={handleClick}>
                    Get Your Quote Today
                  </StyledGetAppButton>
                </div>
                <div style={{ position: "relative" }}>
                  <ReactPlayer
                    url={videoUrl}
                    style={{ margin: "auto" }}
                    poster={get(placeholder, `file.url`)}
                    preload="true"
                    controls
                    muted
                    playing
                    loop
                  />
                </div>
              </Col>
              <Col style={{ padding: "6vh 6vw" }}>
                <p>
                  With Hover’s patented technology, the photos of your home will
                  provide Colorado Siding Repair with a fully measured, 3D model
                  of your home without ever having to step onto your property.{" "}
                </p>
                <p>
                  We will review the measurements and provide you with an
                  accurate, real-time quote. No guessing or approximation!
                </p>
                <p>
                  We know you’re busy; who has time to wait around the house all
                  day for someone to give you a quote that takes only 20
                  minutes? We’ve all experienced the frustration of the “we’ll
                  be there between 9 a.m. and 3 p.m.” ordeal from sales reps, so
                  Colorado Siding Repair is eliminating the wait and putting the
                  power in your hands—literally. We’re giving you back precious
                  time with our new virtual quoting feature via the mobile app,
                  Hover.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <SimpleSvgFooter />
      </Container>
    </PageStyle>
  );
};

export default HoverLandingPage;

const query = graphql`
  {
    video: contentfulAsset(title: { regex: "/video c5v3/ig" }) {
      file {
        url
      }
    }
    placeholder: contentfulAsset(title: { eq: "hover video placeholder" }) {
      title
      file {
        url
      }
      gatsbyImageData(quality: 90)
    }
  }
`;
